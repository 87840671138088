import ClipboardJS from 'clipboard';
import { Tooltip } from 'bootstrap';

const initClipboard = () => {
  const clipboard = new ClipboardJS('.copy-to-clipboard');

  clipboard.on('success', (e) => {
    e.clearSelection();

    const clipboardBtn = document.querySelector('.clipboard-btn');

    const originalTitle = clipboardBtn.getAttribute('data-bs-original-title');

    // Changes the title of tooltip when copying
    clipboardBtn.setAttribute('data-bs-original-title', 'Copied!');

    // finds tooltip instance and initialize with show()
    const tooltip = Tooltip.getOrCreateInstance(clipboardBtn);
    tooltip.show();

    // Puts back the original title for next hover
    clipboardBtn.setAttribute('data-bs-original-title', originalTitle);
  });
};

export default initClipboard;
