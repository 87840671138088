import qs from 'qs';

const navigate = (queryParams) => {
  window.location = `/forms${qs.stringify(queryParams, { addQueryPrefix: true })}`;
};

const initFormAccountSelect = () => {
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  // For Forms
  const selectAccountElement = document.querySelector('#forms_account_id_filter');

  if (selectAccountElement) {
    selectAccountElement.addEventListener('change', (event) => {
      queryParams.page = undefined;
      queryParams.batch_id = undefined;
      queryParams.account_id = event.target.value;
      navigate(queryParams);
    });
  }

  const selectBatchElement = document.querySelector('#forms_batch_id_filter');

  if (selectBatchElement) {
    selectBatchElement.addEventListener('change', (event) => {
      queryParams.page = undefined;
      if (event.target.value) {
        queryParams.batch_id = event.target.value;
      } else {
        queryParams.batch_id = undefined;
      }
      navigate(queryParams);
    });
  }

  // For Coc
  const cocAccountElement = document.querySelector('#coc_account_id_filter');

  if (cocAccountElement) {
    cocAccountElement.addEventListener('change', (event) => {
      window.location = `/cocs?account_id=${event.target.value}`;
    });
  }
};

export default initFormAccountSelect;
