const initAccountLogo = () => {
  const logoElement = document.querySelector('.logo-file-input');
  const formElement = document.querySelector('#account-logo-form');

  const whiteLogoElement = document.querySelector('.white-logo-file-input');
  const whiteFormElement = document.querySelector('#account-white-logo-form');

  if (logoElement) {
    logoElement.addEventListener('change', () => {
      formElement.submit();
    });
  }

  if (whiteLogoElement) {
    whiteLogoElement.addEventListener('change', () => {
      whiteFormElement.submit();
    });
  }
};

export default initAccountLogo;
