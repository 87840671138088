import * as bootstrap from 'bootstrap'

const initPopovers = () => {
  const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  tooltipTriggerList.map((tooltipTriggerEl) => (
    new bootstrap.Tooltip(tooltipTriggerEl)
  ));
};

export default initPopovers;
