const initAutoSubmitInput = () => {
  const autoSubmitInputs = document.querySelectorAll('.auto-submit-input');

  autoSubmitInputs.forEach((element) => {
    element.addEventListener('change', () => {
      const formElement = element.closest('.auto-submit');
      formElement.submit();
    });
  });
};

export default initAutoSubmitInput;
