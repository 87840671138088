import Rails from '@rails/ujs';
import 'bootstrap';

import initFormAccountSelect from '../components/form-account-select';
import initPopovers from '../components/popovers';
import initAccountLogo from '../components/account-logo';
import initQuill from '../components/quill';
import initAutoSubmitInput from '../components/auto-submit-input';
import initClipboard from '../components/clipboard';

Rails.start();

(function () {
  document.addEventListener('DOMContentLoaded', () => {
    initPopovers();
    initFormAccountSelect();
    initAccountLogo();
    initQuill();
    initAutoSubmitInput();
    initClipboard();
  });
})();
