import Quill from 'quill';
import { QUILL_TOOLBAR, QUILL_FORMATS } from '../utils/quill';

const initQuill = () => {
  const quillElements = document.querySelectorAll('.quill-editor-container');

  quillElements.forEach((quillElement) => {
    const inputElement = document.getElementById(quillElement.dataset.targetInput);

    quillElement.insertAdjacentHTML('afterbegin', inputElement.value);

    const editor = new Quill(quillElement, {
      theme: 'snow',
      formats: QUILL_FORMATS,
      modules: {
        toolbar: QUILL_TOOLBAR
      }
    });

    editor.on('text-change', () => {
      const editorElement = quillElement.querySelector('.ql-editor');
      inputElement.value = editorElement.innerHTML;
    });
  });
};

export default initQuill;
